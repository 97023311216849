<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-skeleton-loader
        type="chip"
        transition="fade-transition"
        v-if="loading"
      ></v-skeleton-loader>

      <v-toolbar-title v-else class="headline font-weight-bold"
        >Users ({{ listMeta.total }})</v-toolbar-title
      >

      <v-spacer></v-spacer>

      <v-btn color="primary" class="mb-2" @click="addUserDialog = true">
        <v-icon left> {{ icons.add }} </v-icon>Add User
      </v-btn>
    </v-app-bar>

    <div class="mt-12 mb-6">
      <v-row>
        <v-col cols="12" md="3">
          <v-text-field
            outlined
            clearable
            hide-details
            label="Search"
            @input="doFilter"
            placeholder="Enter name, phone or email"
            v-model="search"
          ></v-text-field>
        </v-col>
      </v-row>
    </div>

    <v-data-table
      :items="users"
      :loading="loading"
      :headers="headers"
      class="elevation-1"
      :options.sync="options"
      :server-items-length="listMeta.total"
      :footer-props="{ 'items-per-page-options': [10, 20, 50, 100] }"
      v-model="selectedUsers"
    >
      <template v-slot:top="{ pagination, options, updateOptions }">
        <v-data-footer
          :pagination="pagination"
          :options="options"
          @update:options="updateOptions"
          items-per-page-text="$vuetify.dataTable.itemsPerPageText"
          :items-per-page-options="[10, 20, 50, 100]"
        />
      </template>
      <template v-slot:[`item.avatar`]="{ item }">
        <user-avatar :avatar="item.avatar" size="32" class="mr-4" />
      </template>
      <template v-slot:[`item.first_name`]="{ item }">
        {{ item.first_name }}
      </template>
      <template v-slot:[`item.account_status`]="{ item }">
        <v-chip class="text-capitalize" :color="getColor(item.blocked_at)" dark>
          {{ !item.blocked_at ? 'Active' : 'Blocked' }}
        </v-chip>
      </template>
      <template v-slot:[`item.date_registered`]="{ item }">
        {{ item.addedDate }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          small
          block
          depressed
          :href="
            $router.resolve({ name: 'user.details', params: { id: item.id } })
              .href
          "
        >
          Edit
        </v-btn>
      </template>
    </v-data-table>

    <AddUserDialog
      v-model="addUserDialog"
      @event="getDataFromApi"
    ></AddUserDialog>

    <v-snackbar v-model="snackbar.show" right :color="snackbar.color">
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn text @click="snackbar.show = false" v-bind="attrs">OK</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { mdiFileExcel, mdiPlus, mdiDownload, mdiCheck, mdiClose } from '@mdi/js'
import ControlsMixin from '@/utils/mixins/Controls'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import UserAvatar from '@/components/UserAvatar'
import AddUserDialog from './components/AddUserDialog.vue'
import debounce from 'lodash/debounce'

export default {
  name: 'UsersPage',

  mixins: [ControlsMixin],

  components: {
    UserAvatar,
    AppBarNavIcon,
    AddUserDialog
  },

  data() {
    return {
      options: {},
      loading: false,
      search: null,
      addUserDialog: false,
      headers: [
        {
          text: '',
          sortable: false,
          value: 'avatar',
          align: 'center'
        },
        {
          text: 'First Name',
          sortable: true,
          value: 'first_name'
        },
        {
          text: 'Last Name',
          sortable: true,
          value: 'last_name'
        },
        {
          text: 'Email',
          sortable: true,
          value: 'email'
        },
        {
          text: 'Profile Email',
          sortable: true,
          value: 'profile_email'
        },
        {
          text: 'Profile Phone Number',
          sortable: true,
          value: 'profile_phone_number'
        },
        {
          text: 'Status',
          sortable: false,
          value: 'account_status'
        },
        {
          text: 'Date Registered',
          sortable: true,
          value: 'date_registered'
        },
        {
          text: 'Actions',
          sortable: false,
          value: 'actions'
        }
      ],
      filters: {
        account_status: null,
        has_other_meal_restrictions: null
      },
      account_status_items: [
        { text: 'All', value: null },
        { text: 'Active', value: 'active' },
        { text: 'Onboarded', value: 'onboarded' },
        { text: 'Blocked', value: 'blocked' }
      ],
      icons: {
        export: mdiFileExcel,
        add: mdiPlus,
        download: mdiDownload,
        check: mdiCheck,
        close: mdiClose
      },
      snackbar: {
        show: false,
        message: null,
        color: null
      },
      selectedUsers: []
    }
  },

  computed: {
    ...mapState({
      users: state => state.user.list,
      listMeta: state => state.user.listMeta
    })
  },

  methods: {
    ...mapActions({
      getUsers: 'user/getUsers',
      exportToExcel: 'user/exportToExcel'
    }),

    ...mapMutations({
      clearUsers: 'user/clearUserList'
    }),

    doFilter: debounce(function() {
      this.getDataFromApi()
    }, 600),

    async getDataFromApi() {
      this.loading = true
      this.clearUsers()
      await this.getUsers({
        search: this.search,
        filters: this.filters,
        ...this.options
      })
      this.loading = false
    },

    getColor(status) {
      if (status) return 'red'

      return 'green'
    },

    showSnackbar(message, color) {
      this.snackbar.message = message
      this.snackbar.show = true
      this.snackbar.color = color
    }
  },

  watch: {
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true
    }
  }
}
</script>
