<template>
  <div>
    <v-dialog v-model="dialog" max-width="600" persistent>
      <v-form @submit.prevent="addUser" ref="form">
        <v-card>
          <v-card-title class="text-h5">Add User</v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    outlined
                    required
                    label="First Name"
                    hide-details="auto"
                    v-model="form.first_name"
                    :error-messages="form.$getError('first_name')"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    outlined
                    required
                    label="Last Name"
                    hide-details="auto"
                    v-model="form.last_name"
                    :error-messages="form.$getError('last_name')"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    outlined
                    required
                    label="Email"
                    hide-details="auto"
                    v-model="form.email"
                    :error-messages="form.$getError('email')"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    outlined
                    required
                    hide-details="auto"
                    label="Profile Email"
                    v-model="form.profile_email"
                    :error-messages="form.$getError('profile_email')"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    outlined
                    required
                    hide-details="auto"
                    label="Profile Phone Number"
                    v-model="form.profile_phone_number"
                    :error-messages="form.$getError('profile_phone_number')"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <div class="flex-grow-1"></div>
            <slot>
              <v-btn @click="closeDialog" text>Cancel</v-btn>
              <v-btn type="submit" color="primary" :loading="form.$busy"
                >Submit</v-btn
              >
            </slot>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-snackbar v-model="snackbar.show" right>
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn text color="green" v-bind="attrs" @click="snackbar.show = false">
          Ok
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Form from '@/utils/form'
import User from '@/models/User'
import { mdiClose, mdiPlus } from '@mdi/js'

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialog: this.value,
      form: new Form({
        first_name: null,
        last_name: null,
        email: null,
        phone_number: null,
        profile_email: null,
        profile_phone_number: null
      }),
      activePicker: null,
      snackbar: {
        show: false,
        message: null
      },
      icons: {
        add: mdiPlus,
        close: mdiClose
      }
    }
  },
  methods: {
    addUser() {
      this.form.$busy = true
      this.form.$clearErrors()
      new User(this.form.$data())
        .save()
        .then(() => {
          this.dialog = false
          this.showSnackbar('User was successfully added!')
          this.$refs.form.reset()
          this.form.$clearErrors()
          this.$emit('event')
        })
        .catch(err => {
          if (err.response.status === 422) {
            this.form.$setErrors(err.response.data.errors)
          }
        })
        .finally(() => (this.form.$busy = false))
    },
    closeDialog() {
      this.dialog = false
      this.$refs.form.reset()
      this.form.medications = ['']
      this.form.$clearErrors()
    },
    save(date) {
      this.$refs.menu.save(date)
    },
    showSnackbar(message) {
      this.snackbar.message = message
      this.snackbar.show = true
    }
  },
  watch: {
    dialog(val) {
      this.$emit('input', val)
    },
    value(val) {
      this.dialog = val
      if (val) {
        this.selectedCountryCode = '61'
      }
    }
  }
}
</script>
